<template>
    <div>
        <div class="hb-primary hb-design-system-border-radius px-4 white--text mb-6">
            <hb-header :divider="false">
                <hb-page-header title="Radios">
                    <template v-slot:description>
                        <div>
                            import { HbRadio, HbRadioGroup } from 'hummingbird-aviary';
                        </div>
                        <div class="mt-2">
                            All of the same vuetify props, slots, &amp; events as "v-radio-group" can be used here in "hb-radio-group". For the list of the vuetify available props, slots, &amp; events, go to the vuetify documentation link for "v-radio-group" here: <hb-link href="https://v2.vuetifyjs.com/en/api/v-radio-group/" target="_blank">https://v2.vuetifyjs.com/en/api/v-radio-group/</hb-link>
                        </div>
                        <div class="mt-2">
                            All of the same vuetify props, slots, &amp; events as "v-radio" can be used here in "hb-radio" in addition to the custom ones which are listed at the bottom of this page. For the list of the vuetify available props, slots, &amp; events, go to the vuetify documentation link for "v-radio" here: <hb-link href="https://v2.vuetifyjs.com/en/api/v-radio/" target="_blank">https://v2.vuetifyjs.com/en/api/v-radio/</hb-link>
                        </div>
                    </template>
                </hb-page-header>
            </hb-header>
        </div>

        <hb-card title="Radio Examples + Code" class="ma-0 pa-0">
            
            <hb-form label="Radios" full>

                <hb-radio-group v-model="radioGroup1">
                    
                    <HbRadio
                        label="Basic radio"
                        value="0"
                    />

                    <HbRadio
                        label="Basic radio disabled"
                        disabled
                        value="1"
                    />
                    
                    <HbRadio
                        label="Basic radio with tooltip"
                        tooltip-header="Tooltip Header"
                        tooltip-body="Tooltip Body"
                        value="2"
                    />
                    
                    <hb-radio value="3">
                        <template v-slot:label>
                            Basic radio using label slot instead of props
                        </template>
                    </hb-radio>

                    <hb-radio value="4">
                        <template v-slot:label>
                            Basic radio using label slot and tooltip slots instead of props
                        </template>
                        <template v-slot:tooltipHeader>
                            Tooltip Header
                        </template>
                        <template v-slot:tooltipBody>
                            Tooltip Body
                        </template>
                    </hb-radio>

                    <HbRadio
                        value="5"
                        label="Basic radio using description prop"
                        description="This is using the description prop"
                    />
                    
                    <hb-radio
                        value="6"
                        label="Basic radio using description slot"
                    >
                        <template v-slot:description>
                            This is using the description slot
                        </template>
                    </hb-radio>

                    <hb-radio
                        value="7"
                        class="my-0"
                    >
                        <template v-slot:content>
                            <v-row no-gutters>
                                <div class="hb-inline-sentence-text">
                                    Radio with one input
                                </div>

                                <HbTextField
                                    condensed
                                    box
                                    medium
                                    v-model="textField"
                                    v-validate.disable="'max:45|email'"
                                    data-vv-scope="default"
                                    id="email"
                                    name="email"
                                    :error="errors.collect('default.email').length > 0"
                                />
                            </v-row>
                        </template>
                    </hb-radio>

                    <hb-radio
                        value="8"
                        class="my-0"
                    >
                        <template v-slot:content>
                            <v-row no-gutters>
                                <div class="hb-inline-sentence-text">
                                    Radio with complex inputs
                                </div>
                                
                                <HbSelect
                                    condensed
                                    box
                                    medium
                                    v-model="selectField"
                                    v-validate.disable="'max:45'"
                                    :items="selectItems"
                                    data-vv-scope="default"
                                    id="type"
                                    name="type"
                                    :error="errors.collect('default.type').length > 0"
                                    placeholder="Select"
                                />

                                <div class="hb-inline-sentence-text">
                                    some text in between
                                </div>
                                
                                <HbTextField
                                    condensed
                                    box
                                    medium
                                    v-model="textField2"
                                    v-validate.disable="'max:45'"
                                    data-vv-scope="default"
                                    id="phone"
                                    name="phone"
                                    :error="errors.collect('default.phone').length > 0"
                                />
                            </v-row>
                        </template>
                    </hb-radio>

                    <hb-radio
                        value="9"
                        class="my-0"
                        tooltip-header="Tooltip Header"
                        tooltip-body="Tooltip Body"
                        tooltip-after-content
                        description="This is using the description prop"
                    >
                        <template v-slot:content>
                            <v-row no-gutters>
                                <div class="hb-inline-sentence-text">
                                    Another radio with complex inputs
                                </div>
                                
                                <HbSelect
                                    condensed
                                    box
                                    small
                                    v-model="selectField2"
                                    v-validate.disable="'max:45'"
                                    :items="['$','%']"
                                    data-vv-scope="default"
                                    id="type"
                                    name="type"
                                    :error="errors.collect('default.type').length > 0"
                                />
                                
                                <HbTextField
                                    condensed
                                    box
                                    medium
                                    v-model="textField3"
                                    v-validate.disable="'max:45'"
                                    data-vv-scope="default"
                                    id="phone"
                                    name="phone"
                                    :error="errors.collect('default.phone').length > 0"
                                />

                                <div class="hb-inline-sentence-text">
                                    and then the end of the sentence.
                                </div>
                            </v-row>
                        </template>
                    </hb-radio>

                </hb-radio-group>
                
            </hb-form>

            <hb-card class="pa-6" color="#E0F5F5" no-title>
<pre>
&lt;hb-radio-group v-model="radioGroup1">
                    
    &lt;HbRadio
        label="Basic radio"
        value="0"
    />

    &lt;HbRadio
        label="Basic radio disabled"
        disabled
        value="1"
    />
    
    &lt;HbRadio
        label="Basic radio with tooltip"
        tooltip-header="Tooltip Header"
        tooltip-body="Tooltip Body"
        value="2"
    />
    
    &lt;hb-radio value="3">
        &lt;template v-slot:label>
            Basic radio using label slot instead of props
        &lt;/template>
    &lt;/hb-radio>

    &lt;hb-radio value="4">
        &lt;template v-slot:label>
            Basic radio using label slot and tooltip slots instead of props
        &lt;/template>
        &lt;template v-slot:tooltipHeader>
            Tooltip Header
        &lt;/template>
        &lt;template v-slot:tooltipBody>
            Tooltip Body
        &lt;/template>
    &lt;/hb-radio>

    &lt;HbRadio
        value="5"
        label="Basic radio using description prop"
        description="This is using the description prop"
    />
    
    &lt;hb-radio
        value="6"
        label="Basic radio using description slot"
    >
        &lt;template v-slot:description>
            This is using the description slot
        &lt;/template>
    &lt;/hb-radio>

    &lt;hb-radio
        value="7"
        class="my-0"
    >
        &lt;template v-slot:content>
            &lt;v-row no-gutters>
                &lt;div class="hb-inline-sentence-text">
                    Radio with one input
                &lt;/div>

                &lt;HbTextField
                    condensed
                    box
                    medium
                    v-model="textField"
                    v-validate.disable="'max:45|email'"
                    data-vv-scope="default"
                    id="email"
                    name="email"
                    :error="errors.collect('default.email').length > 0"
                />
            &lt;/v-row>
        &lt;/template>
    &lt;/hb-radio>

    &lt;hb-radio
        value="8"
        class="my-0"
    >
        &lt;template v-slot:content>
            &lt;v-row no-gutters>
                &lt;div class="hb-inline-sentence-text">
                    Radio with complex inputs
                &lt;/div>
                
                &lt;HbSelect
                    condensed
                    box
                    medium
                    v-model="selectField"
                    v-validate.disable="'max:45'"
                    :items="selectItems"
                    data-vv-scope="default"
                    id="type"
                    name="type"
                    :error="errors.collect('default.type').length > 0"
                    placeholder="Select"
                />

                &lt;div class="hb-inline-sentence-text">
                    some text in between
                &lt;/div>
                
                &lt;HbTextField
                    condensed
                    box
                    medium
                    v-model="textField2"
                    v-validate.disable="'max:45'"
                    data-vv-scope="default"
                    id="phone"
                    name="phone"
                    :error="errors.collect('default.phone').length > 0"
                />
            &lt;/v-row>
        &lt;/template>
    &lt;/hb-radio>

    &lt;hb-radio
        value="9"
        class="my-0"
        tooltip-header="Tooltip Header"
        tooltip-body="Tooltip Body"
        tooltip-after-content
        description="This is using the description prop"
    >
        &lt;template v-slot:content>
            &lt;v-row no-gutters>
                &lt;div class="hb-inline-sentence-text">
                    Another radio with complex inputs
                &lt;/div>
                
                &lt;HbSelect
                    condensed
                    box
                    small
                    v-model="selectField2"
                    v-validate.disable="'max:45'"
                    :items="['$','%']"
                    data-vv-scope="default"
                    id="type"
                    name="type"
                    :error="errors.collect('default.type').length > 0"
                />
                
                &lt;HbTextField
                    condensed
                    box
                    medium
                    v-model="textField3"
                    v-validate.disable="'max:45'"
                    data-vv-scope="default"
                    id="phone"
                    name="phone"
                    :error="errors.collect('default.phone').length > 0"
                />

                &lt;div class="hb-inline-sentence-text">
                    and then the end of the sentence.
                &lt;/div>
            &lt;/v-row>
        &lt;/template>
    &lt;/hb-radio>

&lt;/hb-radio-group>
</pre>
            </hb-card>


        </hb-card>

        <hb-card title="Available Slots + Code" class="mt-4 mb-6">
            <hb-form label="Slots" full>

                <hb-radio-group v-model="radioGroup3">
                    <hb-radio value="0">
                        <template v-slot:label>
                            v-slot:label
                        </template>
                        <template v-slot:tooltipHeader>
                            v-slot:tooltipHeader
                        </template>
                        <template v-slot:tooltipBody>
                            v-slot:tooltipBody
                        </template>
                        <template v-slot:description>
                            v-slot:description
                        </template>
                    </hb-radio>

                    <hb-radio value="1">
                        <template v-slot:content>
                            <v-row no-gutters>
                                <div class="hb-inline-sentence-text">v-slot:content</div>
                                <HbSelect
                                    condensed
                                    box
                                    medium
                                    :items="['Office','Fax']"
                                />
                            </v-row>
                        </template>
                    </hb-radio>
                </hb-radio-group>
                
            </hb-form>

            <hb-card class="pa-6" color="#E0F5F5" no-title>
<pre>
&lt;hb-radio-group v-model="radioGroup3">
    &lt;hb-radio value="0">
        &lt;template v-slot:label>
            v-slot:label
        &lt;/template>
        &lt;template v-slot:tooltipHeader>
            v-slot:tooltipHeader
        &lt;/template>
        &lt;template v-slot:tooltipBody>
            v-slot:tooltipBody
        &lt;/template>
        &lt;template v-slot:description>
            v-slot:description
        &lt;/template>
    &lt;/hb-radio>

    &lt;hb-radio value="1">
        &lt;template v-slot:content>
            &lt;v-row no-gutters>
                &lt;div class="hb-inline-sentence-text">v-slot:content&lt;/div>
                &lt;HbSelect
                    condensed
                    box
                    medium
                    :items="['Office','Fax']"
                />
            &lt;/v-row>
        &lt;/template>
    &lt;/hb-radio>
&lt;/hb-radio-group>
</pre>
            </hb-card>


        </hb-card>

        <hb-card title="HbRadio Props" class="my-6 pb-1">
            <hb-data-table
                :headers="propHeaders"
                :items="propItems"
                class="pa-6"
            >
            </hb-data-table>
        </hb-card>

        <hb-card title="HbRadio Slots" class="my-6 pb-1">
            <hb-data-table
                :headers="slotHeaders"
                :items="slotItems"
                class="pa-6"
                >
            </hb-data-table>
        </hb-card>

    </div>
</template>
<script type="text/babel">

    export default {
        name: "DesignSystemRadios",
        data: function() {
            return {
                radioGroup1: '0',
                radioGroup2: '1',
                radioGroup3: '0',
                textField: '',
                selectField: '',
                selectItems: ['Office','Fax','None'],
                textField2: '',
                selectField2: '',
                textField3: '',
                propHeaders: [
                    { text: 'Name', value: 'name', width: '140' },
                    { text: 'Type', value: 'type' },
                    { text: 'Default', value: 'default', width: '110' },
                    { text: 'Description', value: 'description' },
                ],
                propItems: [
                    { name: 'label', type: 'string', default: 'undefined', description: 'Sets the label text to the right of the radio. An alternative way of setting the label text is by using the "v-slot:label" slot which is useful when you need more complex html/content in the label.' },
                    { name: 'tooltip-header', type: 'string', default: 'undefined', description: 'Sets the label tooltip hover-over header text. You can also use the "v-slot:tooltipHeader" slot to set the tooltip header content (useful for when you need to set more than just a basic string like when you need to set custom html.' },
                    { name: 'tooltip-body', type: 'string', default: 'undefined', description: 'Sets the label tooltip hover-over content text.  You can also use the "v-slot:tooltipBody" slot to set the tooltip body content (useful for when you need to set more than just a basic string like when you need to set custom html.' },
                    { name: 'tooltip-after-content', type: 'boolean', default: 'false', description: 'Places the tooltip directly after the input slot instead of after the label slot.' },
                    { name: 'description', type: 'string', default: 'undefined', description: 'Sets the description content text which goes below the label/tooltip/input content.  You can also use the "v-slot:description" slot to set the description content (useful for when you need to set more than just a basic string like when you need to set custom html.' },
                    
                ],
                slotHeaders: [
                    { text: 'Name', value: 'name' },
                    { text: 'Description', value: 'description' },
                ],
                slotItems: [
                    { name: 'label', description: 'This slot is where you can set the label content. You can also use the "label" prop for this' },
                    { name: 'tooltipHeader', description: 'This slot is where you can set the tooltip header content.' },
                    { name: 'tooltipBody', description: 'This slot is where you can set the tooltip body content.' },
                    { name: 'content', description: 'This slot is where you can set more complex content to the right of the radio. Here is where you would place things such as "<hb-text-field condensed box small>" or "<hb-select condensed box medium>" within a sentence when the design calls for it.' },
                    { name: 'description', description: 'This slot is where you can set description content which goes underneath the label/tooltip/input content.' },
                ]
            };
        }
    }
</script>

<style scoped>

</style>